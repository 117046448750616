<template>
    <v-container fluid v-resize="onResize" class="px-0">
        <v-row align="center" justify="center">
            <v-col cols="11" sm="9">
                <v-card outlined class="about mx-auto">
                    <v-card-title :class="`mt-5 ${getTitleSize()}`" >
                        <v-divider class="ma-5" />
                            About Us
                        <v-divider class="ma-5" />
                    </v-card-title>

                    <v-row align="center" justify="center">
                        <v-col cols="8" md="5" lg="4">
                            <v-img class="white--text align-end font-weight-bold" src="/static/img/profile_pic.jpg" />
                        </v-col>
                    </v-row>

                    <v-card-subtitle ref="cardTitle" class="mt-5 text-center">
                        <v-btn 
                            v-for="page in socialPages" 
                            :key="page.title" 
                            :href="page.link" 
                            target="_blank" 
                            rel="noreferrer"
                            exact 
                            :large="$vuetify.breakpoint.mdAndUp"
                            fab 
                            icon 
                            :class="`${page.class} ${getButtonMargin()}`"
                            :aria-label="`Visit our ${page.title} page`"
                        >
                            <v-icon 
                                :large="$vuetify.breakpoint.mdAndUp"
                                :class="page.class">
                                {{ page.icon }}
                            </v-icon>
                        </v-btn>
                    </v-card-subtitle>

                    <v-card-text ref="cardText" :class="`${getTextSize()} text-center`">
                        <v-row justify="center">
                            <v-col cols="11">
                                <p>
                                We are Vicky and Alex. Passionate and adventurous travelers
                                eager to explore the world and make new friends.
                                </p>
                                <p>
                                As a hobby photographers we are always on the lookout
                                for the perfect sunrise and sunset photo spots.
                                </p>
                                <p>
                                Travelling all around the globe helped us to meet
                                many new people from different cultures which brought
                                us unforgettable memories and lovely friends.
                                </p>
                                <p>
                                We are happy to share our experience with all of you.
                                </p>
                                <p>
                                Give us a hand in the Social Media.
                                </p>
                                <p>
                                Feel Travelling.
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "about",

    metaInfo() {
        return this.meta(this.$options.name, window.location.href);
    },

    computed: {
        ...mapGetters(["meta", "socialPages"])
    },

    data() {
        return {
            maxHeight: 0
        };
    },

    methods: {
        getButtonMargin() {
            return this.$vuetify.breakpoint.smAndDown ? "mx-0" : "mx-5";
        },
        getTextSize() {
            return this.$vuetify.breakpoint.smAndDown ? "body-2" : "headline";
        },
        onResize () {
            this.maxHeight = this.$refs.cardText.clientHeight + this.$refs.cardTitle.clientHeight;
        }
    }
};
</script>

<style lang="scss">
.about {
    background-image: linear-gradient(rgba($secondary_color, 0.74), rgba($secondary_color, 0.74));
}
</style>
